<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center">
        <b-badge variant="light-primary">
          {{ title }}
        </b-badge>
        <am-button
          icon-only
          icon="XIcon"
          variant="flat-danger"
          @click="handleRemove()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'AmAttachmentsPreviewer',
  components: { AmButton, BBadge },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleRemove() {
      this.$emit('remove')
    },
  },
}
</script>

<style scoped>

</style>
