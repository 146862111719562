import { render, staticRenderFns } from "./AmAttachmentsPreviewer.vue?vue&type=template&id=10c51f22&scoped=true&"
import script from "./AmAttachmentsPreviewer.vue?vue&type=script&lang=js&"
export * from "./AmAttachmentsPreviewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c51f22",
  null
  
)

export default component.exports