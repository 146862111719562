<template>
  <!--    Farmer Group Form-->
  <validation-observer
      ref="organizationForm"
      v-slot="{invalid}"
  >
    <am-modal
        :title="`${updateOrganizationMode?'Update':'Create'} Farmer Group`"
        :show="organizationModalOpened"
        :show-overlay="deletingOrganization"
        size="lg"
        @onClose="closeOrganizationModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
              v-model="selectedOrganizationId"
              placeholder="All Organizations"
              :options="organizations"
              label-name="name"
              value-name="id"
              name="organizations"
              @input="populateOrganizationForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
              icon="TrashIcon"
              name="delete_organization"
          >
            <b-dropdown-item
                id="cd_organization"
                @click="deleteOrganization()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
              name="organization_action"
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @click="resetOrganizationForm()"
          />
        </div>
      </b-row>
      <error-display :error="error"/>
      <app-collapse
          accordion
          type="margin"
      >
        <app-collapse-item
            id="organization-details"
            title="Farmer Group Details"
        >
          <div class="row">
            <div class="col-md-6">
              <am-input
                  v-model="organization.name"
                  name="name"
                  label="Name"
                  rules="required"
              />
            </div>
            <div class="col-md-6">
              <am-date-picker
                  id="date_establishment"
                  v-model="organization.date_established"
                  name="date_established"
                  label="Date Established"
                  rules="required"
              />
            </div>
            <div class="col-md-6">
              <am-input
                  v-model="organization.registration_no"
                  name="registration_no"
                  label="Registration Number"
              />
            </div>
          <div class="col-md-6">
            <am-select
                v-model="organization.membership_size_id"
                name="membership_size"
                label="Membership Size"
                rules="required"
                label-name="name"
                value-name="id"
                :options="membershipSizes"
            />
          </div>
          <div class="col-md-6">
            <am-date-picker
                id="date_joined"
                v-model="organization.date_joined"
                name="date_joined"
                label="Date Joined"
                rules="required"
            />
          </div>
          <div class="col-md-6">
            <am-select
                v-model="organization.join_reason_id"
                name="join_reason"
                label="Join Reason"
                rules="required"
                label-name="name"
                value-name="id"
                :options="joinReasons"
            />
          </div>
          </div>
        </app-collapse-item>
        <app-collapse-item
            id="address-details"
            title="Address Details"
        >
          <div class="row">
            <div class="col-md-6">
              <am-select
                  v-model="organization.country_id"
                  name="country"
                  label="Country"
                  rules="required"
                  label-name="name"
                  value-name="id"
                  :options="countries"
              />
            </div>
            <div class="col-md-6">
              <am-select
                  v-model="organization.region_id"
                  name="region"
                  label="Region"
                  rules="required"
                  label-name="name"
                  value-name="id"
                  :options="filteredRegions"
              />
            </div>
            <div class="col-md-6">
              <am-input
                  v-model="organization.district"
                  name="district"
                  label="District"
                  rules="required"
              />
            </div>
            <div class="col-md-6">
              <am-input
                  v-model="organization.community"
                  name="community"
                  label="Community"
                  rules="required"
              />
            </div>
          </div>
        </app-collapse-item>
        <app-collapse-item
            id="financial-info"
            title="Contact Details"
        >
          <div class="row">
            <div class="col-md-6">
              <am-input
                  v-model="organization.email"
                  name="farmer_group_email"
                  label="Farmer Group Email"
                  rules="required|email"
              />
            </div>

            <div class="col-md-6">
              <am-input
                  v-model="organization.contact_person_name"
                  name="contact_person_name"
                  label="Contact Person Name"
                  rules="required"
              />
            </div>

            <div class="col-md-6">
              <am-input
                  v-model="organization.contact_person_phone"
                  name="contact_person_phone"
                  label="Contact Person Phone"
                  rules="required|min_length:10"
              />
            </div>

            <div class="col-md-6">
              <am-input
                  v-model="organization.treasurer_name"
                  name="treasurer_name"
                  label="Treasurer Name"
                  rules="required"
              />
            </div>

            <div class="col-md-6">
              <am-input
                  v-model="organization.treasurer_phone"
                  name="treasurer_phone"
                  label="Treasurer Phone"
                  rules="required|min_length:10"
              />
            </div>

          </div>
        </app-collapse-item>
      </app-collapse>
      <div class="row mt-2">
        <div class="col-12 mb-1">
          <am-file-button
              label="Upload Logo"
              variant="outline-primary"
              @onFileUpload="handleFileUpload"
          />
        </div>
        <div
            v-if="organizationLogo"
            class="col-12 mt-1 mb-2"
        >
          <label>Student Photo</label>
          <div class="d-flex align-items-center">
            <am-avatar-data-url
                :size="60"
                :src="organizationLogo"
            />
            <div class="mr-1"/>
            <am-button
                :icon-only="true"
                icon="XIcon"
                variant="flat-danger"
                @click="removeLogo()"
            />
          </div>
        </div>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
              name="cancel"
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @click="closeOrganizationModal()"
          />
          <am-button
              name="save_organization"
              :label="updateOrganizationMode?'Update':'Create'"
              :disabled="invalid"
              :loading="organizationLoading"
              loading-text="Loading..."
              @click="updateOrganizationMode?updateOrganization():createOrganization()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmFileButton from '@/farmbusiness/components/AmFileButton.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import AmAvatarDataUrl from '@/farmbusiness/components/AmAvatarDataUrl.vue'

const org = {
  id: null,
  name: null,
  registration_no: null,
  date_established: null,
  membership_size_id: null,
  date_joined: null,
  join_reason_id: null,
  country_id: null,
  region_id: null,
  district: null,
  community: null,
  contact_person_name: null,
  contact_person_phone: null,
  treasurer_name: null,
  treasurer_phone: null,
  logo: null,
  email: null,
  delete_logo: false,
}
export default {
  name: 'OrganizationSetup',
  components: {
    AmAvatarDataUrl,
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmFileButton,
    AmSelect,
    AmDatePicker,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      organizationModalOpened: false,
      updateOrganizationMode: false,
      organizationLoading: false,
      deletingOrganization: false,
      selectedOrganizationId: null,
      organization: { ...org },
      organizationLogo: null,
      triggerRegionsReload: false,
    }
  },
  computed: {
    countryId(){
      return this.organization.country_id;
    },
    selectedOrganization() {
      const organization = this.organizations.find(o => o.id === this.selectedOrganizationId)
      if (!organization) {
        return null
      }
      return {
        ...organization,
      }
    },
    organizations() {
      return this.$store.getters['organization/organizations']
    },
    joinReasons() {
      return this.$store.getters['organization/joinReasons']
    },
    membershipSizes() {
      return this.$store.getters['organization/membershipSizes']
    },
    countries() {
      return this.$store.getters['organization/countries']
    },
    regions() {
      return this.$store.getters['organization/regions']
    },
    filteredRegions() {
      return this.regions.filter(re => re.country_id === +this.organization.country_id).sort((a,b)=>{
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
    },
  },
  watch: {
    countryId(val){
      this.organization.region_id = null;
    },
    modalOpened(opened) {
      this.organizationModalOpened = opened
    },
    id(id) {
      this.selectedOrganizationId = id
    },

  },
  methods: {
    handleFileUpload(upload) {
      this.organizationLogo = upload.dataUrl
      this.organization.logo = upload.file
    },
    removeLogo() {
      this.organization.logo = null
      this.organization.delete_logo = true
      this.organizationLogo = null
    },
    resetOrganizationForm() {
      this.organization = { ...org }
      this.selectedOrganizationId = null
      this.updateOrganizationMode = false
      this.organizationLogo = null
      this.$refs.organizationForm.reset()
    },
    populateOrganizationForm(organization) {
      if (this.selectedOrganization) {
        this.updateOrganizationMode = true
        this.organization = {
          ...this.selectedOrganization,
          logo: null
        }
        this.organizationLogo = this.selectedOrganization.logo
        setTimeout(()=>{
          this.organization.region_id = this.selectedOrganization.region_id
        },700)
      } else {
        this.resetOrganizationForm()
      }
    },
    closeOrganizationModal() {
      this.organizationModalOpened = false
      this.$emit('modalClosed')
    },
    createOrganization() {
      this.error = {}
      this.organizationLoading = true
      const organization = serialize(this.organization)
      this.$store.dispatch('organization/createOrganization', organization)
          .then(res => {
            this.showSuccess('Created farmer group successfully')
            this.resetOrganizationForm()
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to create farmer group')
          })
          .finally(() => {
            this.organizationLoading = false
          })
    },
    updateOrganization() {
      this.error = {}
      if (!this.organization.id) {
        return
      }
      this.organizationLoading = true
      if (!this.organization.logo) {
        delete this.organization.logo
      }
      const organization = serialize(this.organization)
      this.$store.dispatch('organization/updateOrganization', {
        organization,
        id: this.organization.id
      })
          .then(res => {
            this.showSuccess('Updated farmer group successfully')
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to update farmer group')
          })
          .finally(() => {
            this.organizationLoading = false
          })
    },
    deleteOrganization() {
      if (!this.selectedOrganizationId) {
        this.showInfo('Please select a farmer group')
        return
      }
      this.deletingOrganization = true
      this.$store.dispatch('organization/deleteOrganization', this.selectedOrganizationId)
          .then(() => {
            this.showSuccess('Deleted Successfully')
            this.resetOrganizationForm()
          })
          .catch(() => {
            this.showError('Failed to delete farmer group')
          })
          .finally(() => {
            this.deletingOrganization = false
          })
    },
  }
  ,
}
</script>
