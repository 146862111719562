<template>
  <b-dropdown-item @click="onClick()">
    <feather-icon
      :icon="icon"
      class="mr-50"
    />
    <span> {{ text }}</span>
  </b-dropdown-item>
</template>

<script>
import { BDropdownItem } from 'bootstrap-vue'

export default {
  name: 'AmDropdownItem',
  components: {
    BDropdownItem,
  },
  props: {
    icon: { type: String, default: 'Edit2Icon' },
    text: { type: String, default: 'Action' },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>

</style>
