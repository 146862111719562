<template>
  <!--    Farmer Group Form-->
  <validation-observer
    ref="organizationNotificationForm"
    v-slot="{invalid}"
  >
    <am-modal
      title="Send Notification"
      :show="notificationModalOpened"
      :show-overlay="sendingNotification"
      size="md"
      @onClose="closeOrganizationModal()"
    >
      <error-display :error="error" />
      <am-select
        v-model="notification.notification_type"
        :options="notificationTypes"
        name="notification_type"
        label="Notification Type"
        rules="required"
        label-name="label"
        value-name="id"
      />
      <div v-if="isSms">
        <am-text-area
          v-model="notification.content"
          name="content"
          placeholder="Enter some content..."
          rules="required"
          label="Content"
          resize
        />
      </div>
      <div v-else>
        <am-input
          v-model="notification.subject"
          label="Subject"
          name="subject"
          rules="required"
        />
        <am-rich-text
          v-model="notification.content"
          name="content"
          placeholder="Enter some content..."
          label="Content"
          rules="required"
          @rawText="handleRawText"
        />
        <am-file-button
          label="Upload Attachments"
          variant="outline-primary"
          icon="PaperclipIcon"
          multiple
          @onFileUpload="handleFileUploads"
        />
        <am-attachments-previewer
          v-for="(file,index) in notification.attachments"
          :key="index"
          :title="file.name"
          class="mt-1"
          @remove="()=>{removeAttachment(index)}"
        />
      </div>
      <b-form-checkbox
        v-model="scheduled"
        class="mt-1 mb-1"
      >
        Schedule Notification
      </b-form-checkbox>
      <am-date-picker
        v-if="scheduled"
        v-model="notification.date"
        label="Date to Send"
        name="scheduled_date"
        rules="required"
        enable-time
      />

      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeOrganizationModal()"
          />
          <am-button
            name="send_notification"
            label="Send"
            :disabled="invalid"
            :loading="sendingNotification"
            loading-text="Loading..."
            @click="sendNotification()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BFormCheckbox,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmFileButton from '@/farmbusiness/components/AmFileButton.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmTextArea from '@/farmbusiness/components/AmTextArea.vue'
import AmRichText from '@/farmbusiness/components/AmRichText.vue'
import AmAttachmentsPreviewer from '@/farmbusiness/components/AmAttachmentsPreviewer.vue'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'

export default {
  name: 'OrganizationNotification',
  components: {
    ErrorDisplay,
    AmAttachmentsPreviewer,
    AmRichText,
    AmTextArea,
    AmModal,
    AmButton,
    AmFileButton,
    AmSelect,
    AmDatePicker,
    AmInput,
    ValidationObserver,
    BRow,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    selectedOrganizations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: {},
      notificationModalOpened: false,
      sendingNotification: false,
      scheduled: false,
      rawText: null,
      notification: {
        subject: null,
        content: null,
        attachments: [],
        notification_type: 'email',
        date: null,
      },
      notificationTypes: [
        {
          id: 'sms',
          label: 'SMS Notification',
        },
        {
          id: 'email',
          label: 'Email Notification',
        },
      ],
    }
  },
  computed: {
    isSms() {
      return this.notification.notification_type === 'sms'
    },
  },
  watch: {
    isSms(val) {
      if (val) {
        this.notification.content = this.rawText
      }
    },
    modalOpened(opened) {
      this.notificationModalOpened = opened
      this.clearForm()
    },
  },
  methods: {
    clearForm() {
      this.notification.attachments = []
      this.notification.content = null
      this.notification.notification_type = 'email'
      this.notification.date = null
      this.notification.subject = null
      this.scheduled = false
      this.rawText = null
    },
    handleRawText(text) {
      this.rawText = text
    },
    handleFileUploads(uploadedFiles) {
      this.notification.attachments = uploadedFiles
    },
    removeAttachment(index) {
      this.notification.attachments.splice(index, 1)
    },
    closeOrganizationModal() {
      this.notificationModalOpened = false
      this.$emit('modalClosed')
    },
    sendNotification() {
      // clear useless fields
      this.error = {}
      if (this.isSms) {
        this.notification.subject = null
        this.notification.attachments = []
      }
      if (!this.scheduled) {
        this.notification.date = null
      }
      const data = new FormData()
      data.append('subject', this.notification.subject)
      data.append('content', this.notification.content)
      data.append('notification_type', this.notification.notification_type)
      data.append('date', this.notification.date || '')
      data.append('ids', JSON.stringify(this.selectedOrganizations.map(no => no.id)))
      this.notification.attachments.forEach(a => {
        data.append('attachments[]', a)
      })
      this.sendingNotification = true
      this.$store.dispatch('organization/sendNotification', data)
        .then(() => {
          if (this.scheduled) {
            this.showSuccess('Notification has been scheduled successfully')
          } else {
            this.showSuccess('Notification is being sent')
          }
        })
        .catch(err => {
          this.error = err
          this.showError('Failed to send notification')
        }).finally(() => {
          this.sendingNotification = false
        })
    },
  }
  ,
}
</script>
