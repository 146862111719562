<template>
  <validation-provider
    #default="{ errors }"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="label"
        :for="name"
      >{{ label }}</label>
      <quill-editor
        :id="name"
        ref="quillEditor"
        :class="`${isDarkMode?'am-dark':''}`"
        :options="editorOptions"
        :placeholder="placeholder"
        :value="value"
        @input="inputChanged"
      />
      <small class="text-danger text-capitalize">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@core/utils/validations/validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AmRichText',
  components: {
    BFormGroup,
    ValidationProvider,
    quillEditor,
  },
  props: {
    name: {
      type: String,
      default: 'input',
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    resize: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: '3',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['code-block'],
          ],
        },
        placeholder: this.placeholder,
        theme: 'snow',
      },
    }
  },
  computed: {
    isDarkMode() {
      const skin = this.$store.getters['appConfig/skin']
      return skin === 'dark'
    },
    editor() {
      return this.$refs.quillEditor.quill
    },
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    suffixIconClicked(e) {
      this.$emit('onSuffixIconClick', e)
    },
    prefixIconClicked(e) {
      this.$emit('onPrefixIconClick', e)
    },
    enterPressed(e) {
      this.$emit('onEnterPressed', e)
    },
    inputChanged(e) {
      this.$emit('input', e)
      this.$emit('rawText', this.editor.getText())
    },
  },
}
</script>

<style>
.am-dark .ql-toolbar .ql-stroke {
  fill: #fff !important;
  stroke: #fff !important;
}
.am-dark .quill-editor > .ql-container > .ql-editor.ql-blank::before{
  color: #b4b4b4 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
}
.ql-container > .ql-editor.ql-blank::before{
  color: #b4b4b4 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
}
.am-dark .ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}

.am-dark .ql-toolbar .ql-picker {
  color: #fff !important;
}
</style>
