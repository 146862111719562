<template>
  <div class="">
    <am-list-page
      title="Farmer Groups"
      :columns="columns"
      :rows="organizations"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      :show-profile-photo="true"
      photo-key="logo"
      enable-multi-select
      @onRowSelect="handleRowSelect"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-button
            variant="outline-primary"
            icon="MessageCircleIcon"
            label="Notify"
            tool-tip-text="Send SMS & Email Notifications to organizations"
            class="mr-1"
            @click="showOrganizationNotificationModal()"
          />
          <am-button
            icon="SettingsIcon"
            label="Farmer Groups"
            tool-tip-text="Manage Farmer Groups"
            class=""
            @click="showOrganizationSetupModal()"
          />
        </div>
      </template>
      <template #actions="{row}">
        <am-button
            icon-only
            icon="Edit2Icon"
            variant="flat-primary"
            tool-tip-text="Edit organization details"
            @click="showOrganizationSetupModalForEdit(row.item)"
        />
      </template>
    </am-list-page>
    <organization-setup
      :id="selectedOrganizationId"
      :modal-opened="organizationsModalOpened"
      @modalClosed="closeModal('organizations')"
    />
    <organization-notification
      :selected-organizations="selectedOrganizations"
      :modal-opened="organizationsNotificationModalOpened"
      @modalClosed="closeModal('notification')"
    />
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import OrganizationSetup from '@/views/administration/organizations/OrganizationSetup.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmDropdownItem from '@/farmbusiness/components/AmDropdownItem.vue'
import OrganizationNotification from '@/views/administration/organizations/OrganizationNotification.vue'
import showToast from '@/farmbusiness/mixins/showToast'

export default {
  name: 'Index',
  components: {
    OrganizationNotification,
    AmDropdownItem,
    AmDropdown,
    OrganizationSetup,
    AmListPage,
    AmButton,
  },
  mixins: [showToast],
  data() {
    return {
      tableLoading: false,
      organizationsModalOpened: false,
      organizationsNotificationModalOpened: false,
      selectedOrganizationId: null,
      selectedOrganizations: [],
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          label: 'Logo',
          key: 'logo',
        },
        {
          label: 'Id',
          key: 'organization_id',
        },
        {
          label: 'Name',
          key: 'name',
        },
        {
          label: 'Membership Size',
          key: 'membership_size',
        },
        {
          label: 'Community',
          key: 'community',
        },
        {
          label: 'Contact Person',
          key: 'contact_person_name',
        },
        {
          label: 'Phone',
          key: 'contact_person_phone',
        },
        {
          label: 'Date Joined',
          key: 'joined_date_label',
        },

        {
          label: 'Actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    organizations() {
      return this.$store.getters['organization/organizations']
    },
  },
  mounted() {
    this.$store.dispatch('organization/fetchOrganizations')
    this.$store.dispatch('organization/fetchRequiredData')
  },
  methods: {
    handleRowSelect(orgs) {
      this.selectedOrganizations = orgs
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'organizations':
          this.organizationsModalOpened = false
          this.selectedOrganizationId = null
          break
        case 'notification':
          this.organizationsNotificationModalOpened = false
          break
      }
    },
    showOrganizationSetupModalForEdit(item) {
      this.selectedOrganizationId = item.id
      this.organizationsModalOpened = true
    },
    showOrganizationSetupModal() {
      this.organizationsModalOpened = true
    },
    showOrganizationNotificationModal() {
      if (this.selectedOrganizations.length) {
        this.organizationsNotificationModalOpened = true
      } else {
        this.showInfo('Please select at least one organization')
      }
    },
  },
}
</script>

<style scoped>

</style>
